//

import remark from "remark";
import reactRenderer from "remark-react";
import reactHighlight from "react-highlight";
import { Box } from "@material-ui/core";

const processor = remark()
  .use({
    settings: {
      gfm: true,
      breaks: true
    }
  })
  .use(reactRenderer, {
    sanitize: false,
    remarkReactComponents: {
      code: reactHighlight,
      p: Box
    }
  });

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Markdown2React = text => {
  return processor.processSync(text).contents;
};
