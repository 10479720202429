//

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { TitleComponent } from "../Util";

const useStyles = makeStyles({
  root: {
    padding: "1rem",
    maxWidth: "30rem",
    align: "center",
    margin: "auto"
  },
  title: {
    margin: "1rem"
  },
  title2: {
    margin: "1rem"
  },
  body: {
    margin: "1rem"
  }
});

const PrivacyPolicyComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TitleComponent title="Privacy Policy" />
      <Typography variant="h3" className={classes.title}>
        Privacy Policy
      </Typography>
      <Typography variant="h4" className={classes.title2}>
        Google Analytics
      </Typography>
      <Box>
        <Typography variant="body1" className={classes.body}>
          本サイトでは Google, Inc. によるサービス Google Analytics
          を利用して、サイトのアクセス分析を行なっています。
        </Typography>
        <Typography variant="body1" className={classes.body}>
          本サイトへのアクセスで、使用者のIPアドレス、ウェブURL等の情報が Google
          に自動的に送信されます。
        </Typography>
        <Typography variant="body1" className={classes.body}>
          そのためにCookieの読み取り・設定を行なっております。
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyComponent;
