import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem";

export const InnerLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
);

export const ListItemInnerLink = React.forwardRef<
  HTMLAnchorElement,
  RouterLinkProps
>((props, ref) => (
  <ListItem button component={InnerLink} to={ref} {...props} />
));

export const ListItemLink: React.FC<ListItemProps<
  "a",
  { button?: true }
>> = props => {
  return <ListItem button component="a" {...props} />;
};

export class TitleComponent extends React.Component<{ title: string }> {
  componentDidMount(): void {
    document.title = this.props.title;
  }

  render(): React.ReactNode {
    return <React.Fragment />;
  }
}
