//

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { BlogPostModel } from "../Model/BlogPost";
import BlogTags from "./BlogTags";
import { InnerLink } from "../Util";
import { Markdown2React } from "../Bridge";

const useStyles = makeStyles({
  root: {
    margin: "1.5rem 0",
    padding: "0 calc(2vw)"
  },
  header: {},
  title: {
    textDecoration: "none",
    fontSize: "4rem"
  },
  date: {
    opacity: 0.5
  },
  content: {}
});

const formatDate = (dateStr: string): string => {
  return dateStr.substr(0, 10);
};

const BlogPostComponent: React.FC<BlogPostModel> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <InnerLink to={`/${props.symbol}`} className={classes.title}>
          <Typography variant="h4">{props.title}</Typography>
        </InnerLink>
        <Typography variant="body2" className={classes.date}>
          {formatDate(props.date)}
        </Typography>
        <BlogTags tags={props.tags} />
      </Box>
      <Box className={classes.content}>
        <Typography variant="body2" component="div" className="blog-context">
          {Markdown2React(props.body)}
        </Typography>
      </Box>
    </Box>
  );
};

export default BlogPostComponent;
