//

import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
  Redirect
} from "react-router-dom";
import { Box, Zoom, Container, Fab, useScrollTrigger } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import NavBar from "./Component/NavBar";
import Top from "./Top";
import Resume from "./Component/Resume";
import Blog from "./Component/Blog";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import NotFound from "./Component/NotFound";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontSize: "1.2rem",
      minHeight: "100vh",
      paddingBottom: "4rem"
    },
    scrollToTop: {
      position: "fixed",
      bottom: "4rem",
      right: theme.spacing(2)
    },
    container: {
      margin: 0,
      padding: 0
    }
  })
);

class ScrollToTopOnChangedRaw extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render(): React.ReactNode | null {
    return null;
  }
}

const ScrollToTopOnChanged = withRouter(ScrollToTopOnChangedRaw);

const SmoothScrollToTopButton: React.FC<{
  children?: React.ReactNode;
}> = props => {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        className={classes.scrollToTop}
      >
        {children}
      </Box>
    </Zoom>
  );
};

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Box className={classes.root}>
        <ScrollToTopOnChanged />
        <Box id="back-to-top-anchor" />
        <NavBar />
        <Container className={classes.container}>
          <Box className="_RoutingArea">
            <Switch>
              <Redirect from="/index(.html)?" to="/" />
              <Route exact path="/" component={Top} />
              <Route exsct path="/privacypolicy" component={PrivacyPolicy} />
              <Route exact path="/resume" component={Resume} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/p:page" component={Blog} />
              <Route exact path="/blog/tags/:tag" component={Blog} />
              <Route exact path="/blog/tags/:tag/p:page" component={Blog} />
              <Route exact path="/archives/:yy" component={Blog} />
              <Route exact path="/archives/:yy/p:page" component={Blog} />
              <Route exact path="/archives/:yy/:mm" component={Blog} />
              <Route exact path="/archives/:yy/:mm/p:page" component={Blog} />
              <Route exact path="/:yy/:mm/:dd/:title" component={Blog} />
              <Route component={NotFound} />
            </Switch>
          </Box>
        </Container>
      </Box>
      <SmoothScrollToTopButton>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </SmoothScrollToTopButton>
    </BrowserRouter>
  );
};

export default App;
