//

import React from "react";
import { Box, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InnerLink } from "../Util";

const useStyles = makeStyles({
  root: {
    padding: "0.4rem"
  },
  chip: {
    margin: "0.2rem"
  }
});

const BlogTagsComponent: React.FC<{ tags: string[] }> = props => {
  const classes = useStyles();

  if (props.tags.length === 0) {
    return <React.Fragment />;
  }
  return (
    <Box className={classes.root}>
      {props.tags.map(tag => (
        <InnerLink to={`/blog/tags/${tag}`} key={tag} className="simple-link">
          <Chip label={tag} key={tag} className={classes.chip} />
        </InnerLink>
      ))}
    </Box>
  );
};

export default BlogTagsComponent;
