//

import React from "react";
import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DiUnitySmall, DiGoogleCloudPlatform } from "react-icons/di";
import { FaAws, FaDocker, FaInfo } from "react-icons/fa";

import ProgressComponent from "./Progress";
import { TitleComponent } from "../Util";
import { ResumeItemModel, ResumeCommentModel } from "../Model/ResumeItem";

const useStyles = makeStyles({
  root: {
    maxWidth: "30rem",
    align: "center",
    margin: "auto",
    padding: "0 calc(2vw)",
    color: "#666"
  },
  list: {
    color: "#555"
  },
  listitem: {
    alignItems: "flex-start"
  },
  icon: {
    display: "inline-flex",
    fontSize: "3rem",
    marginRight: "0.5rem",
    opacity: 0.4
  },
  item: {},
  inline: {
    marginRight: "0.5rem"
  },
  primary: {},
  secondary: {
    marginLeft: "1rem"
  },
  comments: {
    display: "inline-flex"
  }
});

const items: ResumeItemModel[] = [
  {
    topic: "AWS",
    icon: <FaAws />,
    comments: [
      { title: "EBS", desc: "2016~2019" },
      { title: "Lambda", desc: "2018~2019" },
      { title: "Route53", desc: "2018~2019" }
    ]
  },
  {
    topic: "GCP",
    icon: <DiGoogleCloudPlatform />,
    comments: [
      { title: "BigQuery", desc: "2015~2018" },
      { title: "DataFlow", desc: "2015~2016" }
    ]
  },
  {
    topic: "Unity",
    icon: <DiUnitySmall />,
    comments: [
      { title: "Version", desc: "5.4~2018.3" },
      { title: "Device", desc: "iOS/Android, 2016~2019" },
      { title: "UCB", desc: "2016~2019" },
      { title: "...", desc: "Socket.IO Client + UDP" }
    ]
  },
  {
    topic: "Docker",
    icon: <FaDocker />,
    comments: [
      { title: "EBS", desc: "2016~2019" },
      { title: "On-premises", desc: "2015~2019" },
      { title: "QNAP", desc: "2015~2019" }
    ]
  },
  {
    topic: "TypeScript",
    icon: <FaInfo />,
    comments: [
      { title: "Frontend", desc: "In-house tools for games, 2016~2019" },
      { title: "Backend", desc: "In-house tools for games, 2016~2019" },
      { title: "PVP Server", desc: "Socket.IO for Unity Game, 2016~2019" }
    ]
  },
  {
    topic: "C#",
    icon: <FaInfo />,
    comments: [
      { title: "Unity", desc: "2016~2019" },
      { title: "Mono", desc: "a little, 2016~2019" }
    ]
  },
  {
    topic: "C++",
    icon: <FaInfo />,
    comments: [
      { title: "Mobile devices", desc: "Embeded, iOS and Android, 2005~2015" },
      {
        title: "Game consoles",
        desc: "PlayStation and PlayStation2, 1997~2005"
      },
      { title: "Windows", desc: "1997~2005" }
    ]
  },
  {
    topic: "C",
    icon: <FaInfo />,
    comments: [
      { title: "Mobile devices", desc: "Embeded, iOS and Android, 1997~2012" }
    ]
  },
  {
    topic: "Objective-C",
    icon: <FaInfo />,
    comments: [{ title: "iOS", desc: "Game Platform SDK, 2011~2015" }]
  },
  {
    topic: "Etc",
    icon: <FaInfo />,
    comments: [
      { title: "MongoDB" },
      { title: "Vue.js" },
      { title: "Cocos2d-x" },
      { title: "Let's Encrypt" },
      { title: "Java" },
      { title: "OpenVG" },
      { title: "JsonSchema" }
    ]
  }
];

const ResumeComment: React.FC<ResumeCommentModel> = props => {
  const classes = useStyles();

  return (
    <Box key={props.title}>
      <Box className={classes.comments}>
        <Typography
          component="span"
          variant="body1"
          className={classes.inline}
          color="textPrimary"
        >
          {props.title}
        </Typography>
      </Box>
      <Box className={classes.comments}>
        <Typography
          component="span"
          variant="body2"
          className={classes.inline}
          color="textSecondary"
        >
          {typeof props.desc === "undefined" ? "" : props.desc}
        </Typography>
      </Box>
    </Box>
  );
};

const ResumeItem: React.FC<ResumeItemModel> = props => {
  const classes = useStyles();

  return (
    <ListItem className={classes.listitem}>
      <Box className={classes.icon} component="span">
        {props.icon}
      </Box>
      <ListItemText
        className={classes.item}
        disableTypography
        primary={
          <Box className={classes.primary}>
            <Box component="span">{props.topic}</Box>
          </Box>
        }
        secondary={
          <Box className={classes.secondary}>
            {props.comments.map(comment => (
              <ResumeComment {...comment} key={comment.title} />
            ))}
          </Box>
        }
      />
    </ListItem>
  );
};

const ResumeComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <Box key="root" className={classes.root}>
      <TitleComponent title="Bugfire.dev Resume" />
      <ProgressComponent progress={false} />
      <Typography variant="h3">Resume</Typography>
      <List className={classes.list}>
        {items.map(item => (
          <ResumeItem {...item} key={item.topic} />
        ))}
      </List>
    </Box>
  );
};

export default ResumeComponent;
