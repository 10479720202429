import React from "react";

type Props = {
  repo: string;
  theme: string;
  issueTerm: string;
};

class Utterances extends React.Component<Props> {
  private myRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount(): void {
    const script = document.createElement("script");
    script.src = "https://utteranc.es/client.js";
    script.async = true;
    script.crossOrigin = "anonymous";
    script.setAttribute("repo", this.props.repo);
    script.setAttribute("theme", this.props.theme);
    script.setAttribute("issue-term", this.props.issueTerm);
    if (this.myRef.current !== null) {
      this.myRef.current.appendChild(script);
    }
  }

  render(): React.ReactNode {
    return <div className="react-utterances" ref={this.myRef}></div>;
  }
}

export default Utterances;
