//

import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "1em"
  }
});

const ProgressComponent: React.FC<{ progress: boolean }> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {((): React.ReactNode | null => {
        if (props.progress) {
          return <LinearProgress />;
        } else {
          return null;
        }
      })()}
    </Box>
  );
};

export default ProgressComponent;
