//

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { TitleComponent } from "../Util";

const useStyles = makeStyles({
  root: {}
});

const NotFoundComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TitleComponent title="Not Found" />
      <Typography variant="h3">Not Found</Typography>
    </Box>
  );
};

export default NotFoundComponent;
