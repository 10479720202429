//

import React from "react";
import {
  Box,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaGithub, FaTwitter, FaLink } from "react-icons/fa";

import {
  TitleComponent,
  ListItemLink,
  ListItemInnerLink,
  InnerLink
} from "./Util";

const useStyles = makeStyles({
  root: {
    maxWidth: "30rem",
    align: "center",
    margin: "auto",
    color: "#666"
  },
  single: {
    fontSize: "2.2rem",
    lineHeight: "3rem"
  },
  primary: {
    fontSize: "1.5rem"
  },
  secondary: {
    fontSize: "1.2rem",
    opacity: 0.4
  },
  icon: {
    fontSize: "3rem"
  }
});

const MyListItemIcon: React.FC<{ children: React.ReactElement }> = props => {
  const classes = useStyles();
  return <ListItemIcon className={classes.icon}>{props.children}</ListItemIcon>;
};

const MyListItemText: React.FC<{
  primary: string;
  secondary: string;
}> = props => {
  const classes = useStyles();
  return (
    <ListItemText
      disableTypography
      primary={
        <Typography className={classes.primary}>{props.primary}</Typography>
      }
      secondary={
        <Typography className={classes.secondary}>{props.secondary}</Typography>
      }
    />
  );
};

const TopComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TitleComponent title="Top" />
      <Divider />
      <List>
        <ListItemInnerLink className={classes.single} to="/blog">
          Blog
        </ListItemInnerLink>
        <ListItemInnerLink className={classes.single} to="/resume">
          Resume
        </ListItemInnerLink>
      </List>
      <Divider />
      <List>
        <ListItemLink href="https://github.com/bugfire">
          <MyListItemIcon>
            <FaGithub />
          </MyListItemIcon>
          <MyListItemText primary="Github" secondary="bugfire" />
        </ListItemLink>
        <ListItemLink href="https://qiita.com/bugfire">
          <MyListItemIcon>
            <FaLink />
          </MyListItemIcon>
          <MyListItemText primary="Qiita" secondary="bugfire" />
        </ListItemLink>
        <ListItemLink href="https://twitter.com/andobario">
          <MyListItemIcon>
            <FaTwitter />
          </MyListItemIcon>
          <MyListItemText primary="Twitter" secondary="andobario" />
        </ListItemLink>
        <ListItemLink href="https://profile.hatena.ne.jp/bugfire/">
          <MyListItemIcon>
            <FaLink />
          </MyListItemIcon>
          <MyListItemText primary="Hatena" secondary="bugfire" />
        </ListItemLink>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          className={classes.single}
          component={InnerLink}
          to="/privacypolicy"
        >
          Privacy Policy
        </ListItem>
      </List>
    </Box>
  );
};

export default TopComponent;
