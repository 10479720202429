//

export interface BlogQueryParam {
  page: number;
  symbol: string;
  method: "" | "year" | "month" | "post" | "tags";
}

export const DefaultBlogQueryParam: BlogQueryParam = {
  page: -1,
  symbol: "",
  method: ""
};

export const EqualBlogQueryParam = (
  a: BlogQueryParam,
  b: BlogQueryParam
): boolean => {
  return a.page === b.page && a.symbol === b.symbol && a.method === b.method;
};
