//

import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { BlogHeaderModel } from "../Model/BlogHeader";
import { BlogPostModel } from "../Model/BlogPost";
import { BlogQueryParam } from "../Model/BlogQuery";
import { InnerLink } from "../Util";

const useStyles = makeStyles({
  root: {},
  paging: {
    padding: "0 0 1rem 0",
    textAlign: "center"
  },
  icon: {
    fontSize: "2rem",
    color: "#666"
  },
  pageText: {
    margin: "0 1rem 1rem 1rem",
    bottom: "0.22rem",
    position: "relative",
    fontSize: "2rem",
    color: "#666"
  },
  linkText: {
    margin: "0 0.5rem 0.5rem 0.5rem",
    bottom: "0.40rem",
    position: "relative",
    fontSize: "1.5rem",
    color: "#666"
  },
  link: {
    textDecoration: "none"
  }
});

export interface BlogNaviProps {
  pageText: string;
  prevLink: string;
  prevText: string;
  nextLink: string;
  nextText: string;
}

const DefaultBlogNaviProps = {
  pageText: "",
  prevLink: "",
  prevText: "",
  nextLink: "",
  nextText: ""
};

export const BuildBlogNaviProps = (
  headers: BlogHeaderModel[],
  posts: BlogPostModel[],
  queryParam: BlogQueryParam
): BlogNaviProps => {
  const blogNaviProps = { ...DefaultBlogNaviProps };

  if (posts.length === 0 || headers.length === 0) {
    return blogNaviProps;
  }

  if (queryParam.method !== "post") {
    blogNaviProps.pageText = `Page ${queryParam.page}`;
    const path = [""];
    switch (queryParam.method) {
      case "year":
      case "month":
        path.push("archives");
        break;
      case "tags":
        path.push("blog");
        path.push(queryParam.method);
        break;
      case "":
        path.push("blog");
        break;
    }
    if (queryParam.symbol !== "") {
      path.push(queryParam.symbol);
    }
    if (headers[headers.length - 1].symbol !== posts[0].symbol) {
      if (queryParam.page === 2) {
        blogNaviProps.prevLink = path.join("/");
      } else {
        blogNaviProps.prevLink = [...path, `p${queryParam.page - 1}`].join("/");
      }
    }
    if (headers[0].symbol !== posts[posts.length - 1].symbol) {
      blogNaviProps.nextLink = [...path, `p${queryParam.page + 1}`].join("/");
    }
  } else if (posts.length === 1) {
    blogNaviProps.pageText = "";
    const index = headers.findIndex(v => v.symbol === posts[0].symbol);
    if (index >= 0) {
      if (index > 0) {
        blogNaviProps.nextLink = `/${headers[index - 1].symbol}`;
        blogNaviProps.nextText = "古い記事";
      }
      if (index < headers.length - 1) {
        blogNaviProps.prevLink = `/${headers[index + 1].symbol}`;
        blogNaviProps.prevText = "新しい記事";
      }
    }
  }

  return blogNaviProps;
};

const PrevLinkComponent: React.FC<{
  to: string;
  children?: React.ReactNode;
}> = props => {
  const classes = useStyles();

  if (props.to === "") {
    return <React.Fragment />;
  }
  return (
    <InnerLink to={props.to} className="simple-link">
      <FaArrowLeft className={classes.icon} />
      {props.children}
    </InnerLink>
  );
};

const NextLinkComponent: React.FC<{
  to: string;
  children?: React.ReactNode;
}> = props => {
  const classes = useStyles();

  if (props.to === "") {
    return <React.Fragment />;
  }
  return (
    <InnerLink to={props.to} className="simple-link">
      {props.children}
      <FaArrowRight className={classes.icon} />
    </InnerLink>
  );
};

const BlogNaviComponent: React.FC<BlogNaviProps> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paging}>
        <PrevLinkComponent to={props.prevLink}>
          <Box className={classes.linkText} component="span">
            {props.prevText}
          </Box>
        </PrevLinkComponent>
        <Box className={classes.pageText} component="span">
          {props.pageText}
        </Box>
        <NextLinkComponent to={props.nextLink}>
          <Box className={classes.linkText} component="span">
            {props.nextText}
          </Box>
        </NextLinkComponent>
      </Box>
    </Box>
  );
};

export default BlogNaviComponent;
