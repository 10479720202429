//

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography, Divider } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import axios from "axios";
import uuid from "uuid";

import { BlogHeaderModel } from "../Model/BlogHeader";
import { BlogPostModel } from "../Model/BlogPost";
import {
  BlogQueryParam,
  DefaultBlogQueryParam,
  EqualBlogQueryParam
} from "../Model/BlogQuery";
import BlogPostComponent from "./BlogPost";
import BlogHeaderComponent, { BuildBlogHeaderProps } from "./BlogHeader";
import BlogNaviComponent, { BuildBlogNaviProps } from "./BlogNavi";
import { TitleComponent } from "../Util";
import ProgressComponent from "./Progress";
import Utterances from "./Utterances";

const POST_PER_PAGE = 5;

const styles = {
  root: {
    align: "center",
    margin: "auto"
  }
};

interface BlogParams {
  page: string;
  yy: string;
  mm: string;
  dd: string;
  title: string;
  tag: string;
}

type BlogProps = {} & RouteComponentProps<BlogParams> &
  WithStyles<typeof styles>;

interface BlogState {
  newQueryParam: BlogQueryParam;
  state: "none" | "wait" | "fetch" | "filled" | "error";
  uuid: string;

  // showing
  curQueryParam: BlogQueryParam;
  headers: BlogHeaderModel[];
  posts: BlogPostModel[];
  allHeaders: BlogHeaderModel[];
}

const defaultBlogState: BlogState = {
  newQueryParam: DefaultBlogQueryParam,
  curQueryParam: DefaultBlogQueryParam,
  state: "none",
  uuid: "",
  headers: [],
  posts: [],
  allHeaders: []
};

class BlogComponent extends React.Component<BlogProps, BlogState> {
  constructor(props: BlogProps) {
    super(props);

    this.state = {
      ...defaultBlogState
    };
  }

  static getDerivedStateFromProps(
    props: BlogProps,
    state: BlogState
  ): BlogState | null {
    const newState = {
      ...state,
      curQueryParam: state.curQueryParam,
      posts: state.posts,
      headers: state.headers
    };
    const newQueryParam = (newState.newQueryParam = {
      ...DefaultBlogQueryParam
    });

    const params = props.match.params;
    let hasError = false;

    switch (props.match.path) {
      case "/blog/tags/:tag":
      case "/blog/tags/:tag/p:page":
        newQueryParam.method = "tags";
        newQueryParam.symbol = params.tag;
        newQueryParam.page = 1;
        break;
      case "/:yy/:mm/:dd/:title":
        newQueryParam.method = "post";
        newQueryParam.symbol = `${params.yy}/${params.mm}/${params.dd}/${params.title}`;
        break;
      case "/archives/:yy":
      case "/archives/:yy/p:page":
      case "/archives/:yy/:mm":
      case "/archives/:yy/:mm/p:page":
        if (typeof props.match.params.mm !== "undefined") {
          newQueryParam.method = "month";
          newQueryParam.symbol = `${params.yy}/${params.mm}`;
        } else {
          newQueryParam.method = "year";
          newQueryParam.symbol = `${params.yy}`;
        }
        newQueryParam.page = 1;
        break;
      default:
      case "/blog":
      case "/blog/p:page":
        newQueryParam.method = "";
        newQueryParam.symbol = "";
        newQueryParam.page = 1;
        break;
    }

    if (typeof params.page !== "undefined") {
      newQueryParam.page = parseInt(params.page, 10);
      if (isNaN(newQueryParam.page) || newQueryParam.page === 0) {
        hasError = true;
      }
    }

    if (hasError) {
      newState.state = "error";
      return newState;
    }

    if (EqualBlogQueryParam(newQueryParam, state.newQueryParam)) {
      return null;
    }

    newState.state = "wait";
    newState.uuid = uuid.v4();
    return newState;
  }

  componentDidMount(): void {
    if (this.state.state === "wait") {
      this.fetch();
    }
  }

  componentDidUpdate(): void {
    if (this.state.state === "wait") {
      this.fetch();
    }
  }

  componentWillUnmount(): void {
    this.setState({
      ...this.state,
      uuid: "",
      state: "none"
    });
  }

  fetch(): void {
    const uuid = this.state.uuid;
    this.setState({
      ...this.state,
      state: "fetch"
    });

    (async (): Promise<void> => {
      // Get Manifest
      const r = await axios.get("/blogdata/manifest.json");
      if (uuid !== this.state.uuid || this.state.state !== "fetch") {
        return;
      }
      const allHeaders: BlogHeaderModel[] = r.data;

      const newState: BlogState = {
        ...this.state,
        posts: [],
        headers: [],
        state: "filled"
      };

      let visibleHeaders: BlogHeaderModel[] = [];
      const method = this.state.newQueryParam.method;
      const symbol = this.state.newQueryParam.symbol;

      if (method === "post") {
        newState.headers = allHeaders;
        visibleHeaders = allHeaders.filter(header => header.symbol === symbol);
      } else {
        if (method === "tags") {
          newState.headers = allHeaders.filter(
            v => v.tags.indexOf(symbol) >= 0
          );
        } else if (symbol !== "") {
          newState.headers = allHeaders.filter(v =>
            v.symbol.startsWith(symbol)
          );
        } else {
          newState.headers = allHeaders;
        }
        const startIndex =
          newState.headers.length -
          1 -
          (this.state.newQueryParam.page - 1) * POST_PER_PAGE;
        let lastIndex = startIndex - POST_PER_PAGE + 1;
        if (lastIndex < 0) {
          lastIndex = 0;
        }
        for (let i = startIndex; i >= lastIndex; i--) {
          visibleHeaders.push(newState.headers[i]);
        }
      }
      const bodyFiles: { [key: string]: BlogHeaderModel[] } = {};
      visibleHeaders.forEach(header => {
        if (typeof bodyFiles[header.path] === "undefined") {
          bodyFiles[header.path] = [];
        }
        bodyFiles[header.path].push(header);
      });
      const bodyFilenames = Object.keys(bodyFiles);
      // Get Bodies
      const w = await Promise.all(
        bodyFilenames.map(filename => axios.get(`/blogdata/${filename}`))
      );

      // debug for wait
      // await (new Promise((resolve) => setTimeout(resolve, 2000)));

      if (uuid !== this.state.uuid || this.state.state !== "fetch") {
        return;
      }

      const bodies: { [key: string]: string } = {};
      w.forEach((v, index) => {
        bodyFiles[bodyFilenames[index]].forEach(header => {
          bodies[header.symbol] = v.data[header.symbol];
        });
      });
      newState.posts = visibleHeaders.map(header => {
        return { ...header, body: bodies[header.symbol] };
      });
      newState.curQueryParam = newState.newQueryParam;
      newState.allHeaders = allHeaders;
      this.setState(newState);
    })();
  }

  BuildBreadcrumbs(): string {
    if (this.state.posts.length === 0 || this.state.headers.length === 0) {
      return "";
    }
    let breadcrumbs = "";
    if (this.state.curQueryParam.symbol !== "") {
      const prefixSymbolWords = this.state.curQueryParam.symbol.split("/");
      while (
        prefixSymbolWords.length > 0 &&
        prefixSymbolWords[prefixSymbolWords.length - 1] === ""
      ) {
        prefixSymbolWords.pop();
      }
      breadcrumbs = prefixSymbolWords.join(" > ") + " ";
    } else {
      breadcrumbs = "";
    }
    return breadcrumbs;
  }

  render(): React.ReactNode {
    const { classes } = this.props;
    const blogHeaderProps = BuildBlogHeaderProps(
      this.state.headers,
      this.state.posts,
      this.state.curQueryParam,
      this.state.allHeaders
    );
    const blogNaviProps = BuildBlogNaviProps(
      this.state.headers,
      this.state.posts,
      this.state.curQueryParam
    );
    return (
      <Box key="root" className={classes.root}>
        <TitleComponent title="Bugfire.dev Blog" />
        <ProgressComponent progress={this.state.state === "fetch"} />
        <BlogHeaderComponent {...blogHeaderProps} />
        <BlogNaviComponent {...blogNaviProps} />
        {this.state.posts.map(post => {
          return (
            <React.Fragment key={post.symbol}>
              <Divider />
              <BlogPostComponent {...post} />
              {((): React.ReactNode | null => {
                if (this.state.curQueryParam.method === "post") {
                  return (
                    <Utterances
                      repo="bugfire/bugfire.github.io"
                      issueTerm={this.state.curQueryParam.symbol}
                      theme="github-light"
                    />
                  );
                } else {
                  return null;
                }
              })()}
            </React.Fragment>
          );
        })}
        <Divider />
        <BlogNaviComponent {...blogNaviProps} />
        {((): React.ReactNode | null => {
          if (this.state.state === "fetch") {
            return null;
          } else if (
            this.state.state === "filled" &&
            this.state.posts.length === 0
          ) {
            return <Typography variant="h4">記事なし</Typography>;
          }
        })()}
      </Box>
    );
  }
}

export default withStyles(styles)(BlogComponent);
