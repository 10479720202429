//

import React from "react";
import { AppBar, Toolbar, Typography, Link } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { InnerLink } from "../Util";

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      opacity: 0.8,
      flexGrow: 1
    },
    title: {
      opacity: 1.0,
      marginLeft: theme.spacing(2),
      flexGrow: 1,
      fontSize: "2rem",
      fontWeight: 900
    },
    link: {
      color: "white",
      textDecoration: "none"
    }
  })
);

const NavBarComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" align="center" className={classes.title}>
          <Link className={classes.link} component={InnerLink} to="/">
            Bugfire.dev
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarComponent;
