//

import React from "react";
import ReactDOM from "react-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import red from "@material-ui/core/colors/red";
import smoothScroll from "smoothscroll-polyfill";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

smoothScroll.polyfill();

if (window.location.hostname !== "localhost") {
  ReactGA.initialize("UA-93996802-1");
  const history = createBrowserHistory();
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  history.listen(({ pathname }) => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  });
}

const theme = createMuiTheme({
  typography: {
    fontFamily: "sans-serif"
  },
  palette: {
    primary: {
      light: "#4f4",
      main: "#2a2",
      dark: "#080",
      contrastText: "#eeeeee"
    },
    secondary: {
      light: "#44f",
      main: "#22a",
      dark: "#008",
      contrastText: "#dddddd"
    },
    error: red
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 900,
        color: "#666"
      },
      h2: {
        fontWeight: 900,
        color: "#666"
      },
      h3: {
        fontWeight: 900,
        color: "#666"
      },
      h4: {
        fontWeight: 900,
        color: "#666"
      },
      body1: {
        color: "#555"
      },
      body2: {
        color: "#555"
      }
    },
    MuiListItem: {
      root: {
        padding: 0
      }
    },
    MuiListItemText: {
      multiline: {
        marginTop: 0,
        marginBottom: 0
      }
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
