//

import React from "react";
import { Box, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InnerLink } from "../Util";

import { BlogHeaderModel } from "../Model/BlogHeader";
import { BlogPostModel } from "../Model/BlogPost";
import { BlogQueryParam } from "../Model/BlogQuery";

const useStyles = makeStyles({
  root: {
    padding: "0.4rem"
  },
  chip: {
    margin: "0.2rem"
  },
  breadcrumbs: {
    margin: "0 0 0 1rem",
    maxWidth: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#666"
  }
});

export interface BlogHeaderPropsLink {
  to: string;
  tag: string;
  key: string;
}

export interface BlogHeaderProps {
  breadcrumbs: string;
  tags: BlogHeaderPropsLink[];
  months: BlogHeaderPropsLink[];
}

export const BuildBlogHeaderProps = (
  headers: BlogHeaderModel[],
  posts: BlogPostModel[],
  queryParam: BlogQueryParam,
  allHeaders: BlogHeaderModel[]
): BlogHeaderProps => {
  let breadcrumbs = "";
  if (posts.length !== 0 && headers.length !== 0 && queryParam.symbol !== "") {
    const prefixSymbolWords = queryParam.symbol.split("/");
    while (
      prefixSymbolWords.length > 0 &&
      prefixSymbolWords[prefixSymbolWords.length] === ""
    ) {
      prefixSymbolWords.pop();
    }
    breadcrumbs = prefixSymbolWords.join(" > ") + " ";
  }
  const tagMap: { [key: string]: { key: string; count: number } } = {};
  const monthMap: { [key: string]: { key: string; count: number } } = {};
  allHeaders.forEach(v => {
    v.tags.forEach(tag => {
      if (typeof tagMap[tag] === "undefined") {
        tagMap[tag] = {
          key: tag,
          count: 0
        };
      }
      tagMap[tag].count++;
    });
    const month = v.symbol.substr(0, 7);
    if (typeof monthMap[month] === "undefined") {
      monthMap[month] = {
        key: month,
        count: 0
      };
      monthMap[month].count++;
    }
  });
  const tags = Object.values(tagMap)
    .sort((a, b) => b.count - a.count)
    .map(v => {
      return {
        to: `/blog/tags/${v.key}`,
        tag: `${v.key}(${v.count})`,
        key: v.key
      };
    });
  const months = Object.values(monthMap)
    .sort((a, b) => a.key.localeCompare(b.key))
    .map(v => {
      return {
        to: `/archives/${v.key}`,
        tag: `${v.key}(${v.count})`,
        key: v.key
      };
    });
  return { breadcrumbs, tags: tags, months: months };
};

const BlogHeaderComponent: React.FC<BlogHeaderProps> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box>
        {props.months.map(tag => (
          <InnerLink to={tag.to} key={tag.tag} className="simple-link">
            <Chip
              label={tag.tag}
              key={tag.tag}
              className={classes.chip}
              size="small"
            />
          </InnerLink>
        ))}
      </Box>
      <Box>
        {props.tags.map(tag => (
          <InnerLink to={tag.to} key={tag.tag} className="simple-link">
            <Chip
              label={tag.tag}
              key={tag.tag}
              className={classes.chip}
              size="small"
            />
          </InnerLink>
        ))}
      </Box>
      <Box className={classes.breadcrumbs} style={{ whiteSpace: "nowrap" }}>
        {props.breadcrumbs}
      </Box>
    </Box>
  );
};

export default BlogHeaderComponent;
